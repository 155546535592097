<template>
<div class="d-flex flex-column flex-root" style="height: 100%;">
  <div class="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center" :style="image">

    <div class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
      <h1 class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">Oops!</h1>
      <p class="font-weight-boldest display-4">Something went wrong here.</p>
      <p class="font-size-h3">We're working on it and we'll get it fixedas soon possible.You can back or use our Help Center.</p>
    </div>

  </div>
</div>
</template>

<script>
export default {
  computed:{
    image(){
      /* Usamos require para tener acceso a las rutas creadas en vue.config.js*/
      let image = require('@public/media/error/bg5.jpg')
      return `background-image: url(${image})`
    }
  }
}
</script>
